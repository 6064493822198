




















































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { UserStore } from '@/store/user'
import { IModalParams, ModalStore, ModalType } from '~/store/modal'
import AlertBox from '#/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class SubscriptionPage extends Vue {
	ModalType = ModalType
	billYearly = false

	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer
	@UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
}
